export default {
  bingsus: [
    {
      id: '001',
      korName: '클래식 빙수',
      engName: 'classic red bean bingsu',
      imageUri: require('../../assets/images/menu/dessert/bingsu/001.png'),
      price: 18000,
      desc: '부드러운 우유 얼음에 국내산 단팥과 쫄깃한 떡을 올린 클래식 팥빙수',
    },
  ],
  macarons: [
    {
      id: '001',
      korName: '마카롱 10개 세트',
      engName: 'macaron set (10ea)',
      imageUri: require('../../assets/images/menu/dessert/macaron/001.png'),
      price: 32000,
      desc: '바삭바삭한 과자사이에 부드러운 크림이 풍부하게 들어간 아티제 대표 디저트',
    },
    {
      id: '002',
      korName: '마카롱 20개 세트',
      engName: 'macaron set (20ea)',
      imageUri: require('../../assets/images/menu/dessert/macaron/002.png'),
      price: 64000,
      desc: '바삭바삭한 과자사이에 부드러운 크림이 풍부하게 들어간 아티제 대표 디저트',
    },
  ],
  cookies: [
    {
      id: '001',
      korName: '(G)쿠키세트',
      engName: 'cookie set (G)',
      imageUri: require('../../assets/images/menu/dessert/cookie/001.png'),
      price: 77000,
      desc: '아티제 쿠키를 다양하게 구성한 선물세트',
    },
    {
      id: '002',
      korName: '(P)쿠키세트',
      engName: 'cookie set (P)',
      imageUri: require('../../assets/images/menu/dessert/cookie/002.png'),
      price: 39000,
      desc: '아티제 쿠키를 다양하게 구성한 선물세트',
    },
    {
      id: '003',
      korName: '호두넛',
      engName: 'walnut cookie',
      imageUri: require('../../assets/images/menu/dessert/cookie/003.png'),
      price: 15000,
      desc: '호두를 꿀에 졸여 달콤함과 고소함이 느껴지는 쿠키스타일의 캔디',
    },
    {
      id: '004',
      korName: '코코넛 튜일',
      engName: 'coconut tuile',
      imageUri: require('../../assets/images/menu/dessert/cookie/004.png'),
      price: 11000,
      desc: '코코넛 슬라이스를 넣은 바삭한 식감의 프랑스식 쿠키',
    },
    {
      id: '005',
      korName: '아몬드',
      engName: 'almond cookie',
      imageUri: require('../../assets/images/menu/dessert/cookie/005.png'),
      price: 11000,
      desc: '유기농 밀가루와 아몬드가 토핑된 쿠키',
    },
    {
      id: '008',
      korName: '쇼콜라 프로겐트',
      engName: 'chocolat prokent',
      imageUri: require('../../assets/images/menu/dessert/cookie/008.png'),
      price: 11000,
      desc: '프랑스산 AOP 버터와 유기농 밀가루에 아몬드를 넣은 쿠키',
    },
    {
      id: '012',
      korName: '레몬필 사브레',
      engName: 'lemon peel sablé',
      imageUri: require('../../assets/images/menu/dessert/cookie/012.png'),
      price: 14000,
      desc: '생레몬과 레몬필이 들어가 새콤하고 상큼한 레몬 쿠키',
    },
    {
      id: '016',
      korName: '클래식 바닐라 쿠키',
      engName: 'classic vanilla cookie',
      imageUri: require('../../assets/images/menu/dessert/cookie/016.png'),
      price: 12000,
      desc: '바닐라빈이 들어가 은은한 바닐라향이 느껴지는 쿠키',
    },
    {
      id: '017',
      korName: '클래식 얼그레이 쿠키',
      engName: 'classic earlgrey cookie',
      imageUri: require('../../assets/images/menu/dessert/cookie/017.png'),
      price: 12000,
      desc: '은은한 얼그레이향이 느껴지는 쿠키',
    },
    {
      id: '020',
      korName: '아몬트 플로랑탱',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/020.png'),
      price: 5000,
      desc: '달콤한 캐러멜과 고소한 아몬드 풍미를 느낄 수 있는 디저트',
    },
    {
      id: '021',
      korName: '클래식 마들렌',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/021.png'),
      price: 3600,
      desc: '레스큐어 A.O.P 버터를 사용하여 만든 촉촉한 마들렌',
    },
    {
      id: '022',
      korName: '클래식 휘낭시에',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/022.png'),
      price: 3600,
      desc: '레스큐어 A.O.P 버터를 사용하여 만든 쫀득한 휘낭시에',
    },
    {
      id: '023',
      korName: '클래식 초코 마들렌',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/023.png'),
      price: 3600,
      desc: '레스큐어 A.O.P 버터를 사용하여 만든 달콤한 초코 마들렌',
    },
    {
      id: '024',
      korName: '클래식 유자 마들렌',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/024.png'),
      price: 3600,
      desc: '레스큐어 A.O.P 버터와 제주산 유자를 넣어 부드럽고 상큼한 맛의 마들렌',
    },
    {
      id: '025',
      korName: '바닐라 바니샌드',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/025.png'),
      price: 2500,
      desc: '바니 쿠키 사이에 화이트 초콜릿을 샌드하고 은은한 바닐라 향을 더한 샌드',
    },
    {
      id: '026',
      korName: '쇼콜라 바니샌드',
      engName: '',
      imageUri: require('../../assets/images/menu/dessert/cookie/026.png'),
      price: 2500,
      desc: '바니 쿠키 사이에 밀크 초콜릿을 샌드해 부드럽고 달콤한 샌드',
    },
  ],
};
